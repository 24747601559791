<script setup lang="ts">
import {BoxLayout, DisplayMode} from "@shopware-pwa/composables-next";
import {AlgoliaProduct} from "~/types/AlgoliaResult";
import {BevcoProductPrice} from "~/types/BevcoProductPrice";
import useMediumThumbnail from "~/composables/useMediumThumbnail";
import Cookies from "js-cookie";
import formatPriceShort from "~/helpers/FormatPriceShort";

const props = withDefaults(
    defineProps<{
        product: AlgoliaProduct;
        price?: BevcoProductPrice | null;
        displayGross?: boolean;
        layoutType?: BoxLayout;
        displayMode?: DisplayMode;
        smallerItem?: boolean;
    }>(),
    {
        layoutType: "standard",
        displayMode: "standard",
        displayGross: true,
        smallerItem: false
    }
);
const displayPrice = ref(0);
const storeMode = Cookies.get("mode") ?? "privat";
const discount = ref<string|null>(null);
const discountType = ref<'percentage'|'monetary'>('percentage');
const listPrice = ref(0);
const cheapestTierQty = ref(0);
const showLowStockMessage = ref(false);
provide('showLowStockMessage', showLowStockMessage);

const {product} = toRefs(props);
provide('product', product);

const hasMht = product.value.customFields?.apport_has_mht_tag ?? false;
let mhtTag = '';
if (hasMht === true) {
    for (const tagKey in product.value.tags) {
        const tag = product.value.tags[tagKey];
        if (tag.name == 'MHT4' || tag.name == 'MHT3' || tag.name == 'MHT2' || tag.name == 'MHT1') {
            mhtTag = tag.name;
        }
    }
}

const hasTierPrice = ref(false);
const price = computed(() => {
    if (!product.value.prices && !product.value.price) return null;
    if (product.value.prices?.length === 0) return product.value.price;
    hasTierPrice.value = true;

    let cheapest = null;
    const firstKey = Object.keys(product.value.prices)[0];
    for (let key in product.value.prices[firstKey]) {
        const p = product.value.prices[firstKey][key];
        if (cheapest === null || p.gross < cheapest.gross) {
            cheapestTierQty.value = p.quantityStart;
            cheapest = p;
        }
    }
    return cheapest;
});

watch(
    () => props.price,
    (value: BevcoProductPrice) => {
        if (value && value.cheapestPrice) {
            if (storeMode == 'erhverv') {
                displayPrice.value = value.cheapestPrice.net;
            } else {
                displayPrice.value = value.cheapestPrice.gross;
            }
            setDiscount(value.cheapestPrice, value.listPrice);
        } else {
            if (storeMode == 'erhverv') {
                displayPrice.value = price.value.net;
            } else {
                displayPrice.value = price.value.gross;
            }
            setDiscount(price.value, price.value.listPrice);
        }
    },
    {
        immediate: true,
    }
);

onMounted(() => {
    if (props.price && props.price.cheapestPrice) {
        if (storeMode == 'erhverv') {
            displayPrice.value = props.price.cheapestPrice.net;
        } else {
            displayPrice.value = props.price.cheapestPrice.gross;
        }
        setDiscount(props.price.cheapestPrice, props.price.listPrice);
    } else if (price.value) {
        if (storeMode == 'erhverv') {
            displayPrice.value = price.value.net;
        } else {
            displayPrice.value = price.value.gross;
        }
        setDiscount(price.value, price.value.listPrice);
    }
});

function setDiscount(price, lp) {
    let listPriceValue = 0;
    let discountValue = null;
    let discountAmount = 0;

    if (mhtTag !== '' && product.value.customFields?.highest_previous_price > 0) {
        listPriceValue = product.value.customFields.highest_previous_price as number;

        if (storeMode === 'erhverv') {
            listPriceValue *= 0.8;
            discountAmount = listPriceValue - price.net;
        } else {
            discountAmount = listPriceValue - price.gross;
        }

    } else if (storeMode === 'erhverv' && price.percentage?.net > 0) {
        listPriceValue = lp ? lp.net : 0;
        discountAmount = listPriceValue - price.net;
    } else if (price.percentage?.gross > 0) {
        listPriceValue = lp ? lp.gross : 0;
        discountAmount = listPriceValue - price.gross;
    }

    if (listPriceValue > 0) {
        if (discountAmount > 50) {
            discountType.value = 'monetary';
            discountValue = formatPriceShort(discountAmount);
        } else {
            let discountPercentage = Math.round((discountAmount / listPriceValue) * 100);
            discountType.value = 'percentage';
            discountValue = discountPercentage + '%';
        }
    }

    discount.value = discountValue;
    listPrice.value = listPriceValue;
}



const getProductImage = computed(() => {
    const coverImage = product.value.images.find((image) => image.isCover);
    return useMediumThumbnail(coverImage);
});

function getProductRelativeUrl(productUrl) {
    const url = new URL(productUrl)
    return url.pathname;
}

const certFlags = computed(() => {
    if (!product.value.properties) return [];
    const certProperty = product.value.properties["Certificering"];
    if (!certProperty || certProperty.length < 1) return [];
    let certs = [];
    for (let i = 0; i < certProperty.length; i++) {
        certs.push(certProperty[i].name);
    }
    return certs;
});

const oldPrice = computed(() => {
    if (listPrice.value > 0) {
        return listPrice.value;
    }

    const firstKey = Object.keys(product.value.prices)[0];
    for (let key in product.value.prices[firstKey]) {
        const p = product.value.prices[firstKey][key];
        if (p.quantityStart === 1) {
            return p.gross
        }
    }
});

const firstRating = computed(() => {
    if (product.value.ratings && Object.keys(product.value.ratings).length > 0) {
        return product.value.ratings[Object.keys(product.value.ratings)[0]];
    }
    return null;
});
</script>

<template>
<!-- eslint-disable vue/no-v-html -->
<div class="sw-product-card group bg-white w-full h-full px-2">
    <div
        class="flex flex-col h-full"
        :class="{
            'justify-between': !smallerItem,
        }"
    >
        <NuxtLink
            v-if="product && product.url"
            :to="getProductRelativeUrl(product.url)"
        >
            <div class="absolute left-0 top-[5px] md:top-[20px] z-10 max-w-full flex flex-col flex-items-start">
                <div v-if="mhtTag == 'MHT4'"
                     class="product-band mht-band font-semibold text-white bg-brand-red"
                     v-html="$t('product.mht-badge.mht4')"
                />
                <div v-if="mhtTag == 'MHT2' || mhtTag == 'MHT3'"
                     class="product-band mht-band font-semibold text-brand-dark bg-brand-lukewarmPink"
                     v-html="$t('product.mht-badge.mht2')"
                />
                <div v-if="mhtTag == 'MHT1'"
                     class="product-band mht-band font-semibold text-white bg-brand-bottleGreen"
                     v-html="$t('product.mht-badge.mht1')"
                />
                <div
                    v-for="label in product.customLabels"
                    :style="'background-color: ' + label.color"
                    class="product-band text-white mb-[10px]!"
                >{{ label.content }}</div>
                <div v-if="firstRating"
                     class="product-band bg-brand-dark text-white"
                >
                    <span class="font-medium">{{firstRating.rating}}</span> - {{ firstRating.reviewer }}
                </div>
                <div v-if="product.ratings && Object.keys(product.ratings).length > 1"
                     class="product-band bg-brand-dark text-white"
                >
                    <span class="font-medium">{{ $t('listing.seeMore')}}</span>
                </div>
            </div>
            <div class="relative w-full hover:opacity-75"
                :class="[
                    smallerItem ? 'h-32 pt-2' : 'h-50'
                ]"
            >
                <NuxtImg
                    v-if="getProductImage"
                    :src="getProductImage"
                    :alt="product.name"
                    class="w-full h-full object-cover almost-sm:object-contain"
                    height="200"
                    width="260"
                />
                <div class="absolute right-0 top-[-5px] z-20 md:top-4 w-2/5 md:w-1/3 max-w-25 box-border flex justify-end">
                    <div
                        v-if="discount && discountType === 'percentage'"
                        class="discount-blob inline-block text-white font-medium text-[18px] md:text-[20px]"
                    >
                        {{ discount }}
                    </div>
                    <div
                        v-else-if="discount && discountType === 'monetary'"
                        class="discount-blob flex flex-col justify-center text-white font-medium text-[14px] md:text-[16px] line-height-[1.1]!"
                    >
                        <span class="font-normal">{{ $t('general.saveDiscount') }}</span>
                        <span class="pl-[5px]">{{ discount }}</span>
                    </div>
                </div>
                <div class="absolute left-0 bottom-0 flex flex-col">
                    <NuxtImg src="/oekologisk.svg" height="25" alt="Økologisk certificering" loading="lazy"
                         v-if="certFlags.includes('Økologisk')" />
                    <NuxtImg src="/oekologist_eu.svg" height="25" alt="Økologisk (EU) certificering" loading="lazy"
                         v-if="certFlags.includes('Økologisk (EU)')" />
                    <NuxtImg src="/fairtrade.svg" height="25" class="mb-2" alt="Fairtrade certificering" loading="lazy"
                         v-if="certFlags.includes('Fairtrade')" />
                    <NuxtImg src="/rainforest_alliance.svg"  height="25" class="mb-2" alt="Rainforest Alliance certificering" loading="lazy"
                         v-if="certFlags.includes('Rainforest Alliance')" />
                    <NuxtImg src="/utz.svg" height="25" alt="Utz certificering" v-if="certFlags.includes('UTZ')" loading="lazy" />
                </div>
            </div>
            <div class="flex flex-col justify-between flex-1 mt-[20px]">
                <div class="text-[15px] line-height-[1.2]">
                    <div
                        class="w-full overflow-hidden whitespace-nowrap text-ellipsis font-semibold"
                    >
                        {{ product.name }}
                    </div>
                    <div class="w-full overflow-hidden whitespace-nowrap text-ellipsis">
                        {{ product.subTitle|| "&nbsp" }}
                    </div>
                </div>
            </div>
        </NuxtLink>
        <div class="md:mt-3">

            <div class="mt-auto" v-if="product.availableStock > 0 || ! product.isCloseout">
                <div class="product-card-price flex flex-col min-h-[48px] justify-center">
                    <SharedPrice
                        :value="displayPrice"
                        class="font-semibold text-[15px] line-height-[1] m-0 mt-[10px]"
                        :class="{
                            'text-sm': smallerItem,
                            'text-brand-orange': listPrice > 0 || hasTierPrice
                        }"
                    >
                        <template v-slot:afterPrice v-if="hasTierPrice">
                            <span>{{ $t('listing.tierAfter', {qty: cheapestTierQty}) }}</span>
                        </template>
                    </SharedPrice>
                    <SharedPrice
                        v-if="listPrice > 0 || hasTierPrice"
                        class="text-brand-darkGray text-[15px] line-height-[1] m-0"
                        :class="{'line-through': !hasTierPrice}"
                        :value="oldPrice"
                    >
                        <template v-slot:afterPrice v-if="hasTierPrice">
                            <span>{{ $t('listing.tierAfter', {qty: 1}) }}</span>
                        </template>
                    </SharedPrice>
                </div>

            </div>
            <div
                class="flex justify-between position-relative h-[46px] mt-[5px]"
            >
                <div class="flex flex-col justify-center no-gap">
                    <span v-if="showLowStockMessage" class="text-brand-red text-sm">{{ $t('listing.stockExceeded', {quantity: product.availableStock}) }}</span>
                    <span v-else-if="product.isCloseout && product.availableStock <= 0" class="text-sm text-brand-red">{{ $t('listing.soldOut') }}</span>
                    <span v-else-if="product.availableStock > 0" class="text-brand-green text-sm">{{ $t('listing.inStock') }}</span>
                    <span v-else class="text-sm text-brand-red">{{ $t('listing.outOfStock') }}</span>
                </div>
                <ProductCardAddToCart
                    :parent="'ProductCard'"
                    v-if="product.availableStock > 0 || ! product.isCloseout"
                />
            </div>
        </div>
    </div>
</div>
</template>
<style scoped>
.discount-blob {
    background: url('/blob-yellow.svg') no-repeat;
    background-size: contain;
    text-align: center;

    line-height: 57px;
    height: 57px;
    width: 57px;

    @media screen and (min-width: 768px) {
        line-height: 67px;
        height: 67px;
        width: 67px;
    }
}
.product-band {
    text-align: left;
    font-size: 11px;
    line-height: 1;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 8px 6px 8px;
    margin-bottom: 5px;

    &.mht-band {
        font-size: 11px;
    }

    @media screen and (min-width: 768px) {
        font-size: 13px !important;
    }
}
</style>
